.login-container {
  text-align: center;
  margin: 10px;
  background-color: #25083e;
  padding: 25px;
  border-radius: 10px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

/* General styling for the app */
.app-container {
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease-in-out;
}

.app-header {
  background-color: #25083e;
  padding: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;  /* Align items to the left */
}

.hamburger-icon {
  font-size: 30px;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 10px;  /* Add space between hamburger icon and logo */
}

.logo {
  height: 40px;
  width: auto;
  margin-right: 10px; /* Space between logo and hamburger icon */
}

.logo-login {
  height: 40px;
  width: auto;
  margin-bottom: 10px; /* Space between logo and hamburger icon */
}

.side-menu {
  position: fixed;
  left: -250px; /* Initially hidden off-screen */
  top: 0;
  bottom: 0;
  width: 250px;
  background-color: #25083e;
  color: white;
  transition: left 0.3s ease-in-out;
  padding-top: 10px;
  z-index: 1000;
}

.NavHeader {
  padding-left: 10px;
}

.side-menu.open {
  left: 0; /* When menu is open, slide it in */
}

.side-menu ul {
  list-style: none;
  padding: 0;
}

.side-menu ul li a {
  color: white;
  padding: 5px;
  text-decoration: none;
  display: block;
}

.side-menu ul li a:hover {
  background-color: #4e2359;
}

/* Content shift when the side menu is open */
.app-container.menu-open {
  margin-left: 250px; /* Move the content to the right when the menu is open */
}

.container {
  transition: margin-left 0.3s ease-in-out;
}

/* For mobile responsiveness */
@media (max-width: 768px) {
  .app-container {
    transition: none;
  }

  .side-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #25083e;
    color: white;
    transition: transform 0.3s ease-in-out;
    display: none;
    padding-top: 10px;
    z-index: 1000;
    transform: translateX(-100%);
  }

  .side-menu.open {
    transform: translateX(0);
    display: block;
  }

  .side-menu ul {
    list-style: none;
    padding: 0;
    margin-top: 50px;
  }

  .side-menu ul li a {
    color: white;
    padding: 5px;
    text-decoration: none;
    display: block;
  }

  .side-menu ul li a:hover {
    background-color: #4e2359;
  }

  .hamburger-icon {
    margin-right: 20px;
  }

  /* Close button inside the menu */
  .side-menu .close-btn {
    font-size: 40px;
    color: white;
    background: none;
    border: none;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1100;
    transition: transform 0.3s ease-in-out;
  }

  .side-menu .close-btn:hover {
    color: #ff5c5c;
  }

  .side-menu .close-btn:focus {
    outline: none;
  }

  .container {
    transition: margin-left 0.3s ease-in-out;
  }

  .app-container.menu-open .container {
    margin-left: 0;
  }
}

/* For non-mobile (tablet and desktop) */
@media (min-width: 769px) {
  /* Hide the close button on non-mobile devices */
  .side-menu .close-btn {
    display: none;
  }
}

.context-menu-item:hover {
  background-color: lightgrey;
}

.context-menu-item {
  font-size: 12px;
}

.mb-3 {
  margin-bottom: 1.5px !important;
}

.search-help {
  font-style: italic;
  font-size: 12px;
  padding-left: 10px;
}

.table-responsive {
  overflow-x: auto; width: 100%;
}

.headerversion {
  font-size: 9px;
  margin-left: 5px;
}